@keyframes head___1rwz9 {
  0% {
    background-color: #d6b4e7; }
  100% {
    background-color: white; } }

.headerContainer___2WhMu {
  height: 100px;
  position: relative; }

.profilePicContainer___2sXD_ {
  border-radius: 10px;
  height: 100px;
  width: 100px;
  float: right;
  border: 1px solid #793698;
  background: white; }

.profilePicContainer___2sXD_ img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  padding: 5px; }

.nameContainer___Of_2L {
  text-transform: uppercase;
  font-size: 35px; }

.nameContainer___Of_2L h2 {
  border-bottom: 1px solid #793698;
  margin-bottom: 0;
  margin-left: -5px;
  padding-left: 5px;
  font-size: 35px;
  line-height: 1.4;
  font-weight: 600; }

@media (max-width: 768px) {
  .nameContainer___Of_2L h2 {
    margin-right: 5px; } }

.jobTitleContainer___1NBrA {
  text-transform: uppercase; }

@media (max-width: 768px) {
  .jobTitleContainer___1NBrA {
    text-align: center; } }

.jobTitleContainer___1NBrA h3 {
  margin-top: 10px;
  font-size: 16px; }

.timer___8HBia {
  font-size: 8px; }

@media (max-width: 768px) {
  .timer___8HBia {
    display: block; } }

.timer___8HBia .unit___1zeoJ {
  font-size: 16px; }

.timer___8HBia .changed___3mPgY {
  animation-name: head___1rwz9;
  animation-duration: 1s;
  animation-iteration-count: infinite; }
