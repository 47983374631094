.contactData___3Bl28 {
  height: 50px;
  transition: background-color 0.5s ease-out; }

.rowLink___2TkFp:hover .contactData___3Bl28 {
  background-color: #ec0026 !important; }

.contactContainer___3ZOwU {
  margin-bottom: 30px; }

.contactData___3Bl28 .icon___1MKOY {
  width: 50px;
  height: 50px;
  float: left;
  display: flex;
  align-items: center;
  justify-content: center; }

.contactData___3Bl28 .icon___1MKOY svg path {
  fill: white; }

.contactData___3Bl28 .text___cjBOA {
  height: 50px;
  width: calc(100% - 50px);
  float: right;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: -5px 0px 5px -3px #000;
  color: white;
  font-size: 14px;
  text-transform: none; }

.skillSetHeader___23RCO {
  text-transform: uppercase;
  margin-bottom: 20px;
  text-align: center; }
