.experienceContent___1-kJl {
  margin-right: 20px;
  border: 1px solid #793698;
  padding: 15px;
  border-radius: 5px; }

@media (max-width: 768px) {
  .experienceContent___1-kJl {
    margin-right: 0; } }

.experienceHeader___7fkG6 {
  font-size: 18px;
  margin: 0; }

.experienceContainer___tnA5m {
  margin-top: 20px;
  position: relative; }

.experienceContainer___tnA5m:first-child {
  margin-top: 0; }

.prev___1D-MM {
  position: absolute;
  left: 0;
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.next___1vdUo {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; }

.dateAndLocation___1vshO {
  display: flex;
  font-size: 12px;
  color: #793698;
  height: 20px;
  align-items: center; }

.dateAndLocation___1vshO span {
  flex: 1; }

.locationField___dflxN {
  text-align: right; }

.company___3VP3q {
  margin: 0;
  font-size: 16px;
  height: 20px; }

.role___1we10 {
  font-size: 14px;
  font-weight: 200;
  padding-bottom: 10px; }

.highlight___1P71u {
  font-size: 14px;
  line-height: 22px;
  overflow-wrap: break-word; }

.experienceWrapper___QBQIv {
  margin-top: 10px;
  padding-bottom: 20px; }

.experienceWrapper___QBQIv::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 4px; }

.experienceWrapper___QBQIv::-webkit-scrollbar-track {
  background: #bbb; }

.experienceWrapper___QBQIv::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 1px rgba(255, 255, 255, 0.5); }

.client___1ykFK {
  display: inline-block;
  border: 2px solid #4c79bb;
  border-radius: 5px;
  background-color: #f0faff;
  padding: 2px 5px;
  margin-right: 5px; }

.titleWrapper___QznGh {
  display: flex;
  align-items: center;
  margin-bottom: 20px; }

.titleWrapper___QznGh img {
  width: 50px;
  height: 50px;
  margin-right: 10px; }
