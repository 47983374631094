.skillContainer___VrDzu {
  height: 30px; }

.skillName___3qF5l {
  padding-left: 20px;
  text-transform: none;
  font-size: 14px; }

.starsContainer___1OhQx {
  width: 115px;
  float: right;
  padding-right: 20px; }

.starContainer___jjjW_ svg path {
  fill: #960018; }
