.container___1wHAI {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px 25px 25px 25px; }

.content___2-FNy {
  position: relative;
  display: block;
  margin-top: 30px;
  overflow: auto; }

@media (max-width: 768px) {
  .content___2-FNy {
    margin-top: 55px; } }

.sideBar___3JkA7 {
  width: 300px;
  min-height: 400px;
  float: right;
  box-shadow: -7px 0px 5px -3px #aaa;
  background-color: #f1e9f5;
  padding-bottom: 20px;
  border-radius: 4px;
  border: 1px solid #793698; }

.mainSection___1nMX9 {
  margin-right: 300px; }

@media (max-width: 768px) {
  .sideBar___3JkA7 {
    width: 100%;
    float: none; }
  .mainSection___1nMX9 {
    margin-right: 0;
    margin-top: 25px; } }
